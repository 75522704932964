import React, { useState, useEffect } from "react";
import "../../App.css";
import AnimatedText from "../AnimatedText";
import ParticleSphere from "../ParticleSphere";

const Home = () => {
  const [showParticleSphere, setShowParticleSphere] = useState(false);
  const [showInstructions, setShowInstructions] = useState(true);

  useEffect(() => {
    setShowParticleSphere(true);

    const hideInstructions = () => {
      setShowInstructions(false);
      window.removeEventListener("scroll", hideInstructions);
      window.removeEventListener("click", hideInstructions);
      window.removeEventListener("touchstart", hideInstructions);
    };

    window.addEventListener("scroll", hideInstructions);
    window.addEventListener("click", hideInstructions);
    window.addEventListener("touchstart", hideInstructions);

    return () => {
      setShowParticleSphere(false);
      window.removeEventListener("scroll", hideInstructions);
      window.removeEventListener("click", hideInstructions);
      window.removeEventListener("touchstart", hideInstructions);
    };
  }, []);

  return (
    <div className="home-container">
      {showParticleSphere && (
        <div className="particle-sphere-container">
          <ParticleSphere />
        </div>
      )}
      <div className="content-overlay">
        <AnimatedText />
      </div>
      {showInstructions && (
        <div className="interaction-instructions">
          To interact scroll/click to drag/pinch
        </div>
      )}
    </div>
  );
};

export default Home;
