import React, { useState, useEffect } from "react";

const AnimatedText = () => {
  const words = ["ideas", "interfaces", "worlds", "people"];
  const [currentWord, setCurrentWord] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [wordIndex, setWordIndex] = useState(0);

  useEffect(() => {
    const typeSpeed = 150;
    const deleteSpeed = 100;
    const pauseTime = 1000;

    const type = () => {
      const currentFullWord = words[wordIndex];
      if (isDeleting) {
        setCurrentWord(currentFullWord.substring(0, currentWord.length - 1));
      } else {
        setCurrentWord(currentFullWord.substring(0, currentWord.length + 1));
      }

      if (!isDeleting && currentWord === currentFullWord) {
        setTimeout(() => setIsDeleting(true), pauseTime);
      } else if (isDeleting && currentWord === "") {
        setIsDeleting(false);
        setWordIndex((prevIndex) => (prevIndex + 1) % words.length);
      }
    };

    const timer = setTimeout(type, isDeleting ? deleteSpeed : typeSpeed);
    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, [currentWord, isDeleting, wordIndex]);

  return (
    <span className="animated-text">
      connect-<span className="changing-text">{currentWord}</span>
    </span>
  );
};

export default AnimatedText;
