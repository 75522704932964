import React, { useRef, useEffect } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

const ParticleSphere = () => {
  const mountRef = useRef(null);

  useEffect(() => {
    let scene, camera, renderer, controls, particleSystem;
    let geometry, material;
    let isUserInteracting = false;
    const autoRotationSpeed = 0.001;
    let animationFrameId;

    // Capture the current value of mountRef
    const currentMount = mountRef.current;

    const init = () => {
      scene = new THREE.Scene();
      camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        0.1,
        1000
      );
      renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.setPixelRatio(window.devicePixelRatio);
      currentMount.appendChild(renderer.domElement);

      controls = new OrbitControls(camera, renderer.domElement);
      controls.enableDamping = true;
      controls.dampingFactor = 0.05;
      controls.rotateSpeed = 0.5;
      controls.maxDistance = 10;
      controls.enablePan = false;

      geometry = createParticleGeometry();
      material = createParticleMaterial();

      particleSystem = new THREE.Points(geometry, material);
      scene.add(particleSystem);

      camera.position.z = 4;

      window.addEventListener("resize", onWindowResize, false);
      renderer.domElement.addEventListener("mousedown", handleInteractionStart);
      renderer.domElement.addEventListener(
        "touchstart",
        handleInteractionStart
      );
      renderer.domElement.addEventListener("mouseup", handleInteractionEnd);
      renderer.domElement.addEventListener("touchend", handleInteractionEnd);
    };

    const handleInteractionStart = () => {
      isUserInteracting = true;
    };

    const handleInteractionEnd = () => {
      isUserInteracting = false;
    };

    const createParticleGeometry = () => {
      const geometry = new THREE.BufferGeometry();
      const particles = 5000;
      const positions = new Float32Array(particles * 3);
      const colors = new Float32Array(particles * 3);
      const radius = 1.5;

      for (let i = 0; i < particles; i++) {
        const i3 = i * 3;
        const phi = Math.acos(-1 + (2 * i) / particles);
        const theta = Math.sqrt(particles * Math.PI) * phi;

        positions[i3] = radius * Math.cos(theta) * Math.sin(phi);
        positions[i3 + 1] = radius * Math.sin(theta) * Math.sin(phi);
        positions[i3 + 2] = radius * Math.cos(phi);

        const color = new THREE.Color();
        color.setHSL(i / particles, 1.0, 0.5);

        colors[i3] = color.r;
        colors[i3 + 1] = color.g;
        colors[i3 + 2] = color.b;
      }

      geometry.setAttribute(
        "position",
        new THREE.BufferAttribute(positions, 3)
      );
      geometry.setAttribute("color", new THREE.BufferAttribute(colors, 3));

      return geometry;
    };

    const createParticleMaterial = () => {
      return new THREE.PointsMaterial({
        size: 0.015,
        vertexColors: true,
        blending: THREE.AdditiveBlending,
        transparent: true,
      });
    };

    const animate = () => {
      animationFrameId = requestAnimationFrame(animate);

      if (!isUserInteracting) {
        particleSystem.rotation.y += autoRotationSpeed;
      }

      controls.update();
      renderer.render(scene, camera);
    };

    const onWindowResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };

    init();
    animate();

    return () => {
      console.log("with everything, just connect the dots..");
      window.removeEventListener("resize", onWindowResize);
      currentMount.removeChild(renderer.domElement);
      renderer.domElement.removeEventListener(
        "mousedown",
        handleInteractionStart
      );
      renderer.domElement.removeEventListener(
        "touchstart",
        handleInteractionStart
      );
      renderer.domElement.removeEventListener("mouseup", handleInteractionEnd);
      renderer.domElement.removeEventListener("touchend", handleInteractionEnd);
      cancelAnimationFrame(animationFrameId);
      scene.remove(particleSystem);
      if (geometry) geometry.dispose();
      if (material) material.dispose();
      if (controls) controls.dispose();
    };
  }, []);

  return <div ref={mountRef} style={{ width: "100%", height: "100vh" }} />;
};

export default ParticleSphere;
