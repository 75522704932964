import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import "./Syn.css";

const Syn = () => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [error, setError] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [placeholder, setPlaceholder] = useState("Say Wassup..");
  const chatMessagesRef = useRef(null);
  const [conversationId, setConversationId] = useState(null);

  const scrollToBottom = () => {
    if (chatMessagesRef.current) {
      const scrollHeight = chatMessagesRef.current.scrollHeight;
      const height = chatMessagesRef.current.clientHeight;
      const maxScrollTop = scrollHeight - height;
      chatMessagesRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, isTyping]);

  const sendMessage = async () => {
    if (inputMessage.trim() === "") return;

    const newMessage = { text: inputMessage, sender: "user" };
    setMessages((prevMessages) => {
      if (prevMessages.length === 0) {
        setPlaceholder("Type your message here...");
      }
      return [...prevMessages, newMessage];
    });
    setInputMessage("");
    setIsTyping(true);

    try {
      const response = await axios.post(
        "https://syn-chat-dc8c8598d555.herokuapp.com/chat",
        {
          message: inputMessage,
          conversation_id: conversationId,
        },
        {
          headers: {
            "X-API-Key": process.env.REACT_APP_API_KEY,
          },
        }
      );

      const aiResponse = { text: response.data.response, sender: "ai" };
      setMessages((prevMessages) => [...prevMessages, aiResponse]);
      setConversationId(response.data.conversation_id);
      setError("");
    } catch (err) {
      console.error("Error:", err);
      setError("Error Connecting to Syn. Try again shortly.");
    } finally {
      setIsTyping(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  return (
    <div className="syn-container">
      <div className="syn-header">
        <div className="syn-title-container">
          <h1 className="syn-title">SYN</h1>
          <span className="syn-version">v0.1.0</span>
        </div>
        <p className="syn-description">
          If it's C-D related, Syn knows. Just ask.
        </p>
      </div>
      <div className="chat-container">
        <div className="neon-dot-background">
          <div className="neon-dot"></div>
        </div>

        <div className="chat-window">
          <div className="chat-messages" ref={chatMessagesRef}>
            {messages.map((message, index) => (
              <div key={index} className={`message ${message.sender}`}>
                {message.text}
              </div>
            ))}
            {isTyping && (
              <div className="message ai typing-indicator">
                <span></span>
                <span></span>
                <span></span>
              </div>
            )}
          </div>
          <div className="chat-input">
            <input
              type="text"
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder={placeholder}
            />
            <button onClick={sendMessage}>Send</button>
          </div>
          {error && <div className="error-message">{error}</div>}
        </div>
      </div>
    </div>
  );
};

export default Syn;
