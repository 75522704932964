import React, { useState, useEffect } from "react";
import "./LoadingAnimation.css";

const LoadingAnimation = () => {
  const [text, setText] = useState("");
  const [dateTime, setDateTime] = useState(new Date());
  const fullText = "Loading interface..";

  useEffect(() => {
    let i = 0;
    const typingInterval = setInterval(() => {
      if (i < fullText.length) {
        setText(fullText.slice(0, i + 1));
        i++;
      } else {
        clearInterval(typingInterval);
      }
    }, 100);

    const timeInterval = setInterval(() => {
      setDateTime(new Date());
    }, 1000);

    return () => {
      clearInterval(typingInterval);
      clearInterval(timeInterval);
    };
  }, []);

  const formatDate = (date) => {
    return date.toLocaleDateString("en-US", {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  const formatTime = (date) => {
    return date.toLocaleString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
  };

  return (
    <div className="loading-container">
      <div className="text-container">
        <div className="date-time">
          {formatDate(dateTime)} {formatTime(dateTime)}
        </div>
        <div className="command-line">
          <span>$</span>
          <div style={{ position: "relative", marginLeft: "8px" }}>
            {text}
            <span
              className="cursor"
              style={{ left: `${text.length * 0.6}em` }}
            ></span>
          </div>
        </div>
      </div>
      <div className="dots-container">
        <div className="dot dot-1"></div>
        <div className="dot dot-2"></div>
        <div className="dot dot-3"></div>
      </div>
    </div>
  );
};

export default LoadingAnimation;
