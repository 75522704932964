import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <header className="header">
      <nav className="nav-links">
        <Link to="/">Home</Link>
        <Link to="/interfaces">Interfaces</Link>
        <a
          href="https://cnctndots.substack.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Research
        </a>
      </nav>
    </header>
  );
};

export default Header;
